import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueCryptojs from 'vue-cryptojs'
import './assets/css/mycss.css';

createApp(App).use(VueCryptojs).mount('#app')
window.onbeforeunload = function(){  return 'Are you sure you want to leave?';};
async function StorageUsage() {
   if ('storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
    /*  .then(({usage, quota}) => {
        console.log(`Using ${Math.round(usage / (1024 * 1024))} out of ${Math.round(quota / (1024 * 1024))} bytes. This Represents: ${Math.round(usage / quota * 100)}%`);
      });*/
    }
}    
const askPersistanceStore= async()=>{
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist()
    }
    }
await StorageUsage()
await askPersistanceStore()