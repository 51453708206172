<template> 
<div class="allthee">    
    <component v-bind:is="hatkaka('DemoSou')" ref="xbaby" :key="Date.now()"></component>
    <component v-if="todo" v-bind:is="hatkaka('ZwillTell')" :key="Date.now()"></component> 
    <component v-if="!newuzr && !readyuzr" v-bind:is="hatkaka('LogmeModule')" ref="baby" :key="Date.now()"></component> 
    <component v-if="!newuzr && readyuzr" v-bind:is="hatkaka('ReadyMain')" ref="baby" :key="Date.now()"></component> 
</div>
<div v-if="bkdrop" class="backdrop"></div>
</template>
<script setup>
/*eslint no-undef: "error"*/
/*eslint-env node*/ 
/* eslint-disable */
import { golins, todo, readyuzr, newuzr, infuz, qaskSw} from './compojs/Messenger'
import {  ref, defineAsyncComponent } from 'vue';
const baby=ref()
const xbaby=ref()
const bkdrop=ref();
navigator.serviceWorker.onmessage= async (event) => {
    console.log('MESSAGE: ',event.data);
    switch (true) {
    case event.data.hasOwnProperty('ready'):
        readyuzr.value=event.data.ready;
        break;
    case event.data.hasOwnProperty('golins'):
        golins.value={...golins.value,...event.data.golins};await baby.value.getvoix()
        break;        
    case event.data.hasOwnProperty('papa'):        
        (event.data.forZid=="DemoSou") ? xbaby.value.updVarz(event.data.papa, event.data.forZid) : baby.value.updVarz(event.data.papa, event.data.forZid)
        if(event.data.papa.menhistory) {
            setTimeout(() => {
                qaskSw({wish:'recs',zstor:'demonstration',zki:event.data.papa.menhistory[event.data.papa.menhistory.length - 1], vname: 'comments',noDec:true, myZid:'DemoSou'})
            }, 500);          
        }
            //xmen.value= event.data.papa.menhistory.slice(-1)}

        break;
    case event.data.hasOwnProperty('MsG'):
        infuz(event.data['MsG'],event.data.brut,event.data['tm'])
        break 
    case event.data.hasOwnProperty('say'):        
        speak(event.data.say);
        break 
    case event.data.hasOwnProperty('reload'):
        reloadApp(event.data.todo)
        break 
    case event.data.hasOwnProperty('todo'):
        todo.value = event.data.todo;        
        break        
    }
}
const reloadApp=(ztodo)=>{
    todo.value= ztodo 
    setTimeout(() => {
    navigator.serviceWorker.onmessage= async (event) => {}
    window.onbeforeunload = function () {  }
    navigator.serviceWorker.getRegistrations().then( async function(registrations) {for(let registration of registrations) { registration.unregister();}}); 
    window.location.reload(true)
    }, 4000);
}
const hatkaka =(zmdl) =>{return defineAsyncComponent(() => import(/* @vite-ignore */`./Modulos/${zmdl}.vue`))}
const speak = (tsay) => { 
  if (golins.value.italk) {
    const synth = new SpeechSynthesisUtterance();
    synth.onerror = function (event) {};
    if (golins.value.voice) {synth.voice = golins.value.objvoice;}    
    synth.text = tsay
    synth.volume = 1.0; 
    synth.pitch = 0.9; 
    synth.rate = 0.9; 
    synth.lang = golins.value.lg;     
    window.speechSynthesis.speak(synth);
  }
}
</script>
<style scoped>
.allthee{width: 100%;padding-bottom: 50px;}
.backdrop{ position: fixed;top: 0; left: 0; width: 100%; height: 100%; text-align: center; padding:100px;z-index: 6000; background: rgba(0, 0, 0, 0.721); opacity:.7; }
</style>