<template>
  <div class="jstprint">
    <div class="zflx">
      <h4 >Kingdom of Saudi Arabia<br>Ministry of health<br>KING FAHAD HOSPITAL<br>AL BAHA</h4>
      <img id="mlogo" alt="" src="../assets/logo.png">
      <h4 >المملكة العربية السعودية <br> وزارة الصحة <br> مستشفى الملك فهد<br> الباحة</h4>
    </div>
  </div>
  <div class="hdr">
    <div v-if="!readyuzr">
    <h3 class="no-print">Endocrinology & Diabetes<br>Center in Al Baha</h3>   
    </div>    
    <img v-if="!readyuzr" id="blogo" alt="" src="../assets/logo.png" class="blelogo no-print">
    <div v-if="!readyuzr">
    <h3 class="no-print">مركز الغدد الصماء<br>و السكري بالباحة</h3>     
    </div>
  </div>
  <div class="infx" :style="golins.lg=='ar' ? 'direction: rtl;':'direction: ltr;'"></div>
  <div  v-if="!readyuzr" class="courtesy">
    <h4>Under the supervision of <br>Mr. Saeed bin Talal Al-Ghamdi <br>Endocrinology & Diabetes Center </h4>
    <h4>تحت إشراف الأستاذ <br>سعيد بن طلال الغامدي <br>مدير مركز الغدد الصماء والسكري </h4>
  </div>
  <div class="foot no-print">
    <div v-if="readyuzr">
      <span>{{golins.ititle.toUpperCase() +' '+ golins.iuzr.toUpperCase()}}</span>
      <img src="../assets/ar.png" title="عربي"  @click="golins.lg='ar';qaskSw({wish:'run',fsub:'loadMenu',argsnm:'[mname,vupd]',argsval:['MG',{zmenu:null,menhistory:[],iskompo:null}], myZid:'ReadyMain'})">
      <img src="../assets/fr.png" title="FRANCAIS" @click="golins.lg='fr';qaskSw({wish:'run',fsub:'loadMenu',argsnm:'[mname,vupd]',argsval:['MG',{zmenu:null,menhistory:[],iskompo:null}], myZid:'ReadyMain'})">
      <img src="../assets/en.png" title="ENGLISH" @click="golins.lg='en';qaskSw({wish:'run',fsub:'loadMenu',argsnm:'[mname,vupd]',argsval:['MG',{zmenu:null,menhistory:[],iskompo:null}], myZid:'ReadyMain'})">
    </div>
    <div @click="dua=!dua"><span>﴾ &copy; M A L E K - 2023 ﴿</span></div>
    <div v-if="readyuzr">
      <span @click="golins.italk=!golins.italk" :style="(golins.italk) ? 'cursor: pointer;color:#2ff503a8;' : 'cursor: pointer;color:crimson;'" title="VOICE STATUS">{{(golins.italk) ? '🔊 ON':'🔈 OFF'}}</span>
      <span>{{(readyuzr) ? '&#128994; CONNECTED':'&#128308; DISCONNECTED'}}</span>
    </div>    
  </div>
  <div class="dua" v-if="dua" @click="dua=!dua">
    <h3>
بسم الله الرحمان الرحيم<br> 
والصلاة والسلام على أشرف المرسلين.<br> 
اللهم إجعل هذا العمل متقبلاً خالصاً لوجهك الكريم.<br>
اللهم بارك في من ساهم في إنجازه من قريب أو بعيد 
و سدد من عمل به وبارك فيه و في  عمله. <br>
اللهم ارحم والدينا وكل من له الحق علينا. <br>
اللهم آمين والحمد لله رب العالمين. 
    </h3>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { readyuzr,golins, qaskSw} from './compojs/Messenger'
const dua= ref(false)
</script>
<style scoped>
*{font-family: 'Playfair Display', serif !important;}
.zflx{display: flex;width: 100%;text-align: center;}
.zflx * {display: block;}
.zflx h4:first-child{width:50%;text-align: left;}
.zflx h4:last-child{width:50%;text-align: right;}
.zflx img{position: absolute;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  top: 30px;
  width: 100px;height: auto;
  text-align: center;
  z-index: 1; }

.hdr{display: flex; width:100%;text-align: center; color: dodgerblue;margin: 20px auto 10px auto;padding: 10px;}
.hdr div:nth-of-type(1),.hdr div:nth-of-type(2) {width: 100%; margin-bottom: 10px;}
.hdr div:nth-of-type(1) h3{font-size: 100%;text-align: left;margin-left: 10px;padding: 10px;filter: drop-shadow(5px 5px 10px #44444d81);}
.hdr div:nth-of-type(2) h3{text-align: right;font-size: 100%;margin-right: 10px;padding: 10px;filter: drop-shadow(5px 5px 10px #44444d81);}
.blelogo{position: absolute;
  display: block;
  filter: drop-shadow(-5px 5px 10px #03033181);
  margin: auto;
  left: 0;
  right: 0;
  top: 2%;
  width: 15%;height: auto;
  text-align: center;
  z-index: 1;  transition: all 2s;}

.foot{position:fixed;z-index: 100000;left:0%;bottom: 0%;display: flex;width: 100%;background: rgb(255, 255, 255);text-align: center;height: 1.1em
;}
.foot div{display:flex;margin: 0 auto;width:100%;text-align: center;}
.foot div:nth-child(2){color: rgb(129, 153, 176); cursor: pointer;}
.foot div span{display: block;margin: 0 auto;line-height: 1.3em;font-size: .9em;color: #4f5862;}
.foot div img{height: 1.3em;width: auto; cursor: pointer;margin: 0 auto;filter: drop-shadow(5px 5px 10px #44444d81);}
.foot div img:hover{filter: none;}
.courtesy{position: absolute; display: flex;width: 100%;bottom: 25px;left: 0;}
.courtesy h4:nth-of-type(1){width:100%;text-align: left;font-size: 100%;margin-left: 10px;padding-left: 10px;border-left: 2px solid grey;}
.courtesy h4:nth-of-type(2){width:100%;text-align: right;font-size: 100%;margin-right: 10px;padding-right: 10px;border-right: 2px solid grey;}
.courtesy h4{color: rgb(62, 86, 111);text-shadow: 0 0 8px #cdd2dd, 0 0 5px #9cc2e6;}
.dua {position: absolute;top:25%; left: 0;z-index: 100000;;width: 100%;}
.dua h3{background: dodgerblue;color:white; margin: 0 auto;max-width:500px;text-align: justify;font-size: 2em;padding: 20px;border-radius: 10px;direction:rtl;cursor: pointer;}
</style>