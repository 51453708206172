<template>
  <Zentete/>
  <Suspense>    
  <MyHome/>
  </Suspense>
</template>

<script>
import MyHome from './components/HomeModule.vue'
import Zentete from './components/ZenteteBar.vue'

export default {
  name: 'App',
  components: {
    MyHome,
    Zentete
  }
}
</script>
<style>
#app {
  font-family: 'Playfair Display', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  width:100%;
  height: 100%;
}
</style>
