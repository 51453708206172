/*eslint no-undef: "error"*/
/*eslint-env node*/ 
/* eslint-disable */
import { ref , defineAsyncComponent} from "vue";
const CryptoJS = require("crypto-js");
const todo=ref()
export {todo}
const golins=ref({"ititle":"","iauth":"","iuzr": null,"identity":null,"ipass":null,"lg":"en", "demonstration":"demonstration"})
export {golins}
const readyuzr =ref(false)
export {readyuzr}
const newuzr=ref()
export {newuzr}
const logout=()=>{readyuzr.value=false;golins.value={"ititle":"","iauth":"","iuzr": null,"identity":null,"ipass":null,"itkn":null,"credsSrc":"TheCroods"};}
export {logout}
const hatkaka =(zmdl) =>{
  try {
    return defineAsyncComponent(() => import(/* @vite-ignore */`../Modulos/${zmdl}.vue`))
  } catch (error) {
    alert('component not found')
  }
  
}
export {hatkaka}
const revers = (s) => { return s.split("").reverse().join("");}
export {revers}
const decTit=(was)=>{return CryptoJS.MD5(revers(was)).toString(CryptoJS.enc.Hex)}
const decSom = (todec)=> {  return CryptoJS.AES.decrypt(todec, golins.value.mkey,{iv: golins.value.miv}).toString(CryptoJS.enc.Utf8);}
export {decSom}
const encSom = (toenc)=> {  return CryptoJS.AES.encrypt(toenc, golins.value.mkey,{iv: golins.value.miv}).toString()}
export {encSom}
/**get from db js table */
const dbThere=async()=>{
  const zibi= await indexedDB.databases()
  return (Object.keys(zibi).length > 0) ? true : false
}
export {dbThere}
const getMyDad=async(dbnm)=>{return (await indexedDB.databases()).filter(function (wd) {return wd.name==dbnm})[0];}
const openDb=async()=>{ 
if (await getMyDad(golins.value.dbname)) {
  return new Promise((resolve, reject) => {
    let request = indexedDB.open(golins.value.dbname, golins.value.dbvers);
    request.onerror = function (e) {
      reject(e);
    };
    request.onsuccess = function (e) {
      golins.value["zdb"] = e.target.result;
      resolve(
        e.target.result.objectStoreNames.length == 4 ? e.target.result : null
      );
    };
  });
} else {
  infuz("Can not proceed ",true,2000);
}
}

const hatmeThee=async(woher,fsub,noDec)=>{
  const zsub=decTit(fsub)
  //console.log('hatmethee: ','woher',woher,fsub+': ',zsub)
  if (await getMyDad(golins.value.dbname)){
    let db = golins.value.zdb ?? (await openDb())
    if (db){
    return new Promise(function(resolve) {      
      let store = db.transaction([woher],'readwrite').objectStore(woher);  
      var getKey = store.get(zsub);
      getKey.onsuccess = async function() {
      if(getKey.result){        
        resolve((noDec) ?  getKey.result : decSom(getKey.result))
      }else{
        if (woher===golins.value.machina){
          console.log('MACHINA MISSING: |'+fsub+'| zsub: |'+zsub+'|')
          if (await fireUpdate("machina")){resolve(await hatmeThee(woher,fsub,noDec))}
          resolve(null)
        }else{resolve(null)}
      }
      }      
    })   
  }else{logout()}
}else{ logout()  }
}
export {hatmeThee}

const intoDB = async( objrecs, bwin,crypt)=>{
  console.log("WILL SAVE TO: ",bwin)
  let db =golins.value.zdb ?? (await openDb())
  return new Promise(function(resolve) {
    let store = db.transaction([bwin],'readwrite').objectStore(bwin);  
    if (crypt){
      for (const [key, value] of Object.entries(objrecs)) {
        store.put(encSom(value),decTit(key));
      }
    }else{
    for (const [key, value] of Object.entries(objrecs)) {
      store.put(value,key);
    }
  }
    resolve(true)     
  })
} 
export {intoDB}

const clearStoreRecs=async(storenm)=> {
  let db =golins.value.zdb
  const transaction = db.transaction([storenm], "readwrite");
  transaction.oncomplete = (event) => { return true };
  transaction.onerror = (event) => { };
  const objectStore = transaction.objectStore(storenm);
  const objectStoreRequest = objectStore.clear();
  objectStoreRequest.onsuccess = (event) => { return true};
}
export {clearStoreRecs}

const fireUpdate=async(zstor)=>{
  const iformD = new FormData()
  iformD.append('corona',revers(golins.value.zuzer))
  iformD.append('data',encSom(zstor))
  const iresp =  await fetch('api/zfood.php' , { method: 'POST', body: iformD });
  const iisuser = (await iresp.json())
  if (iisuser.valid){
    return await intoDB(iisuser.jedis , golins.value[zstor])
  }
}
export {fireUpdate}

const infuz=async(zmsg,txtbrut,duree) => {
  let txt = (txtbrut) ? zmsg : JSON.parse( await hatmeThee(golins.value.machina, golins.value.lg + "_msgs"))[zmsg];  
  document.querySelector(".infx").innerHTML = txt;
  if(duree){ setTimeout(() => { document.querySelector('.infx').innerHTML=""; }, duree); }
}
export {infuz}

const qester=(objmsj)=>{navigator.serviceWorker.ready.then(worker => {if(worker.active){worker.active.postMessage(JSON.parse(JSON.stringify(objmsj)));}});}
export {qester}

const jsasfn=async (zscr,zargsnm)=>{
  "use strict";
  let AsyncFunction = Object.getPrototypeOf(async function(){}).constructor;
  return new AsyncFunction(zargsnm,zscr);
}
export {jsasfn}
const qaskSw=async(qob)=>{qester({...qob,...{golins:golins.value}})}
export {qaskSw}