/* eslint-disable no-console */
 /* eslint-disable */ 
 /*eslint no-undef: "error"*/
/*eslint-env node*/
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}sw.js`, {
    ready () {
      //console.log('Assalamou 3alaykom.\n' +'Doctor Malek.' )
    },
    registered () {
      //console.log('Service worker has been registered.')
    },
    cached () {
    //console.log('Content has been cached for offline use.')
    },
    updatefound () {
    //  console.log('New content is downloading.')
    },
    updated (registration) {
    //  console.log('New content is available; please refresh.')
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })      
    },
    offline () {
      //console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
